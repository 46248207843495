import rompImg from "../../../assets/images/competitions/optimised/lg/romp-set.png";
import muaImg from "../../../assets/images/competitions/optimised/lg/mua-set.png";
// smaller images - unused example
import ghostSm from "../../../assets/images/competitions/optimised/sm/wetnwild.png";

export const ImageVariants = {
    1: {
        smallImage: rompImg,
        largeImage: rompImg,
    },
    2: {
        smallImage: ghostSm,
        largeImage: muaImg,
    }
};
