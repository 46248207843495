import React from "react";
import ButtonCTA from "../ui/buttons/ButtonCTA";
import heroImage from "../../assets/images/ui/the-edit-hero-cover-latest.png";
import "./Hero.css";

const Hero = () => {
  const dash = '\u2013';
  return (
    <div
      id="hero"
      className="hero flex justify-center w-screen h-full xl:w-3/4 xl:mx-auto mb-24 md:mb-0"
    >
      <div className="hero__grid container grid lg:grid-cols-2 items-start justify-center lg:justify-between">
        <div className="hero__content mx-6 sm:mx-4 md:mx-6 lg:mx-0">
          <h1 className="hero__header text-5xl sm:text-6xl md:text-7xl xl:text-8xl mb-8 transition-all duration-150 ease-in">
            <br />
            <span>Welcome to</span>
            <br />
            <span>The Edit</span>
            <span>- Superdrug&#39;s</span>
            <br />
            Exclusive Magazine.
          </h1>
          <h2 className="hero__text text-xl sm:text-2xl md:text-2xl xl:text-2xl font-light">
            Say hello to The Edit {dash} your quarterly dose of beauty, health, and wellbeing inspiration, brought to you by Superdrug!
            Tailored just for our fabulous customers, it’s packed with expert tips, self-care ideas, and everything you need to feel your best.
          </h2>
          <br />
          <h2 className="hero__text text-xl sm:text-2xl md:text-2xl xl:text-2xl font-bold">
            Don’t Miss Out!
          </h2>
          <h3 className="hero_subtext custom-font text-md sm:text-lg md:text-lg xl:text-md font-extralight mt-4"
          style={{ fontFeatureSettings: "'lnum' 1" }}
          >
            Grab your copy of our latest Health & Wellbeing issue (No. 8), available now in-store. And if that&apos;s not enough, check out even more The Edit content on Superdrug&apos;s YouTube, TikTok, and Instagram channels.
          </h3>
          {/* scroll to competitions CTA */}
          <ButtonCTA />
        </div>
        {/* hero images container */}
        <div className="hero__image lg:ml-auto">
          {/* update/adjust hero img placement via mt */}
          <div className="inline-block lg:ml-auto w-full mt-24">
            {/* hero cover */}
            <img
              src={`${heroImage}`}
              alt="the edit magazine cover hero"
              className="mx-auto lg:ml-auto w-full h-auto"
              style={{ maxWidth: "600px", maxHeight: "900px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
