import React from "react";
import useScrollPosition from "../lib/hooks/useScrollPosition";
import Hero from "../components/layout/Hero";
import Competitions from "../components/layout/Competitions";
import Subscribe from "../components/ui/cards/Subscribe";

const MainPage = () => {
  const sectionRefs = useScrollPosition();

  return (
    // add layout-test here for guides
    <main className="main animate-fadeIn">
      <Hero />
      <Competitions sectionRefs={sectionRefs} />
      <Subscribe sectionRefs={sectionRefs} />
    </main>
  );
};

export default MainPage;
