import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormspreeProvider } from "@formspree/react";
import Card from "../cards/Card";
import Modal from "../../common/modal/ModalPopup";
import styles from "./FormStyles.module.css";
import { CompForm } from "./CompForm";

export default function CompetitionForm({ onClose, index }) {
    // Prop types as a static property - unreachable after return
    CompetitionForm.propTypes = {
        onClose: PropTypes.func.isRequired,
        index: PropTypes.number.isRequired,
    };
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [enteredName, setEnteredName] = useState("");
    const [enteredSurname, setEnteredSurname] = useState("");
    const [enteredEmail, setEnteredEmail] = useState("");
    const [expanded, setExpanded] = useState(false);

    const competitionID = process.env.REACT_APP_FORMSPREE_PROJECT_ID;

    const formNames = {
        "romp-competition": "romp-competition",
        "mua-competition" : "mua-competition"
    };

    const formKey = formNames[index];
    // console.log('formKey as formNames[index]:', formKey)

    const errorHandler = () => {
        setError(null);
    };

    const handleFormSubmit = () => {
        setEnteredName("");
        setEnteredSurname("");
        setEnteredEmail("");
        setSuccess(true);
    };

    const handleCloseForm = () => {
        setSuccess(false);
        onClose();
    };

    const handleExpand = () => {
        setExpanded(true);
        setTimeout(() => {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: "smooth",
            });
        }, 100);
    };

    // handle form key formatting
    function formatFormKey(formKey) {
        // debug form testing
        // console.log('can we get the formKey?:', formKey)
        const words = formKey.split("-");
        let fontSize = "28px";
        // if > 19 chars reduce font-size
        if (words.join("").length > 18) {
            fontSize = "26px";
        }
        const formattedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1)
        );
        return {
            formattedKey: formattedWords.join(" "),
            fontSize: fontSize,
        };
    }

    return (
        // competition form
        <div className="competition-form w-full h-full">
            {error && (
                <Modal
                    title={error.title}
                    message={error.message}
                    onConfirm={errorHandler}
                />
            )}
            {success ? (
                <Modal
                    title="Form Submitted Successfully"
                    message="Thank you for your submission and good luck!"
                    onConfirm={() => setSuccess(false)}
                />
            ) : (
                // via .env
                <FormspreeProvider competition={competitionID}>
                    <Card
                        className={`${styles.input} ${
                            expanded ? "expanded" : ""
                        }`}
                    >
                        <div className={styles.formHeader}>
                            <span onClick={handleExpand}>
                                <h2
                                    className={styles.formKey}
                                    style={{
                                        fontSize:
                                            formatFormKey(formKey).fontSize,
                                    }}
                                >
                                    {formatFormKey(formKey).formattedKey}
                                </h2>
                            </span>
                            <button
                                className={styles.closeButton}
                                onClick={handleCloseForm}
                            >
                                X
                            </button>
                        </div>
                        <div className="mt-8">
                            Enter Your Details
                            <p className={styles.p}>
                                <b>for a chance to win big!</b>
                            </p>
                        </div>
                        <CompForm
                            formKey={formKey}
                            setError={setError}
                            setSuccess={setSuccess}
                            nameChangeHandler={(event) =>
                                setEnteredName(event.target.value)
                            }
                            surnameChangeHandler={(event) =>
                                setEnteredSurname(event.target.value)
                            }
                            emailChangeHandler={(event) =>
                                setEnteredEmail(event.target.value)
                            }
                            enteredName={enteredName}
                            enteredSurname={enteredSurname}
                            enteredEmail={enteredEmail}
                            onFormSubmit={handleFormSubmit}
                        />
                    </Card>
                </FormspreeProvider>
            )}
        </div>
    );
}
